import React from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { Button } from 'react-native-elements';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import Wrapper from './Wrapper';

const styles = StyleSheet.create({
    errorContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    error: {
        backgroundColor: 'red',
        color: 'white',
        alignSelf: 'center',
        paddingLeft: 8,
        paddingRight: 8
    },
    flexGrow: {
        flex: 1
    },
});

class Home extends React.Component {
  static defaultProps = {
        authData: {},
        authState: 'authenticated',
        onAuthStateChange: (next, data) => { console.log(`Home:onAuthStateChange(${next}, ${JSON.stringify(data, null, 2)})`); }
  };

  constructor(props) {
        super(props);
        this.state = {
            authData: this.props.authData,
            authState: this.props.authState,
            modalShowing: false,
            loading: false,
            error: null,
            username: this.props.authData.username || '',
            user: null,
            searchtext: null,
            companies: null
        };
  }

  async onSignOut() {
    try {
            await Auth.signOut({ global: true });
            this.props.onAuthStateChange('signIn', {});            
    } catch (error) {
            console.log('error signing out: ', error);
    }
  }

  async onSearchCompanies() {
    try {
            axios({
                  "method": "GET",
                  "url": "https://api.daizika.com/daizikaapp/prod/v1/search",
                  "headers": {
                    "Authorization": this.state.authData.api_authorization,
                  }, "params": {
                    "q": this.state.searchtext
                    //"default": "1"
                  }
                })
                  .then((response) => {
                    setQuote(response.data.content);
                    setSource(response.data.originator.name)
                  })
                  .catch((error) => {
                    console.log(error)
            })
    } catch (error) {
        console.log('error searching company: ', error);        
    }
  }

  render() {
    let settings = {
        signoutButton: {
            title: 'Sign Out',
            backgroundColor: '#cccccc',
            fontSize: 14,
            enabled: !this.state.loading,
            onPress: () => this.onSignOut()
            },
        companyTextInput: {
            placeholder: 'Type Company Name, Stock Ticker',
            value: this.state.searchtext,
            onChangeText: (text) => this.setState({searchtext: text})
        },
        companySearchButton: {
            title: 'Search',
            backgroundColor: '#cccccc',
            fontSize: 14,
            enabled: !this.state.loading,
            onPress: () => this.onSearchCompanies()
            },
    };
    return(
        <Wrapper>
            {this.state.error !== null && errorComponent}
            <View style={styles.flexGrow}>
              <Text>{this.state.username}!</Text>
              <Button {...settings.signoutButton}/>
            </View>
            <View style={styles.flexGrow}>
              <TextInput {...settings.companyTextInput} />
              <Button {...settings.companySearchButton}/>
            </View>
            <View>
                <Text>{this.state.companies}</Text>
            </View>            
        </Wrapper>
    );
  }
}

export default Home;