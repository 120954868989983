import React from 'react';
import { Image, StatusBar, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    innerContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    topContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        height: 32,
        width: 250
    }
});

const Wrapper = (props) => {
    return (
        <View style={styles.container}>
            <StatusBar barStyle="light-content" />
            <View style={styles.innerContainer}>
                <View style={styles.topContainer}>
                    <Image style={styles.logo} source={require('./media/daizika-logo.png')}/>
                </View>
                {props.children}
            </View>
        </View>
    );
};

export default Wrapper;